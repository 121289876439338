<template>
  <div>
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <Card class="p-col-12 p-mb-1" st>
      <template #content>
        <Fieldset legend="Datos del titular" :toggleable="true">
          <div class="p-grid">
            <div class="p-col-12 p-md-4">
              <p><strong>Titular: </strong> {{ enroll?.contract?.holder?.name }}
                {{ enroll?.contract?.holder?.last_name }}</p>
              <p><strong>Cédula: </strong>{{ enroll?.contract?.holder?.dni }}</p>
              <p><strong>Email: </strong>{{ enroll?.contract?.holder?.email }}</p>
              <p><strong>Es beneficiario: </strong>{{ enroll.is_holder_student === 'y' ? 'SI' : 'NO' }}</p>

            </div>
            <div class="p-col-12 p-md-4">
              <p><strong>N. matrícula: </strong> {{ enroll.sequential }}</p>
              <p><strong>F. matrícula: </strong> {{ $filters.dateFormat(enroll.registration) }}</p>
              <p><strong>Plan: </strong>{{ enroll?.plan?.name }}</p>
            </div>
            <div class="p-col-12 p-md-4">
              <p><strong>Valor: </strong>{{ $filters.moneyFormat(enroll.cost) }}</p>
              <p><strong>Estado: </strong>{{ enroll.status }}</p>
              <p><strong>N. Estudiantes: </strong>{{ studentsNumber }}</p>
            </div>
          </div>
        </Fieldset>
        <Fieldset legend="Datos del contrato" :toggleable="true">
          <div class="p-grid p-mt-5">
            <div class="p-col-12 p-md-4">
              <div class="p-field p-fluid">
                    <span class="p-float-label">
                        <Calendar id="effective_date" :showIcon="true" v-model="data.effective_date"
                                  dateFormat="dd/mm/yy"
                                  :yearNavigator="true" yearRange="2000:2050"
                                  :class="{'p-invalid': submitted && (!data.effective_date )}" class="p-inputtext-sm"/>
                        <label for="effective_date">Fecha de inicio de vigencia</label>
                    </span>
                <small class="p-invalid" v-if="submitted && !data.effective_date">La fecha es requerida</small>
              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-field  p-fluid">
                            <span class="p-float-label">
                                <AutoComplete id="country" :dropdown="true" :multiple="false" field="name"
                                              v-model="data.country" :suggestions="countries"
                                              @complete="getCatalog($event, 'countries', 'name')"
                                              :class="{'p-invalid': submitted && (!data.country )}"/>
                                <label for="country">Pais</label>
                            </span>
                <small class="p-invalid" v-if="submitted && !data.country">El país es requerido</small>
              </div>
            </div>
            <div class="p-col-12 p-md-4">

              <div class="p-field   p-fluid">
                            <span class="p-float-label">
                                <AutoComplete id="city" :dropdown="true" :multiple="false" field="name"
                                              v-model="data.city" :suggestions="cities"
                                              @complete="getCatalog($event, 'cities', 'name', data.country?.id)"
                                              :class="{'p-invalid': submitted && (!data.city)}"/>
                                <label for="city">Ciudad</label>
                            </span>
                <small class="p-invalid" v-if="submitted && !data.city">La ciudad es requerida</small>
              </div>
            </div>
          </div>
          <div class="p-grid p-mt-3">
            <div class="p-col-12 p-md-4">
              <div class="p-field p-fluid">
                            <span class="p-float-label">
                                <InputText id="number" v-model="data.number"
                                           :class="{'p-invalid': submitted && (!data.number )}" class="p-inputtext-sm"
                                           readonly/>
                                <label for="number">Número de contrato</label>
                            </span>
                <small class="p-invalid" v-if="submitted && !data.number">El número de contrato es requerido</small>

              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-field p-fluid">
                          <span class="p-float-label">
                              <InputText id="month" v-model="data.month" class="p-inputtext-sm" readonly/>
                              <label for="month">Mes</label>
                          </span>
              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-field p-fluid">
                          <span class="p-float-label">
                              <InputText id="week" v-model="data.week" class="p-inputtext-sm" readonly/>
                              <label for="city">Semana</label>
                          </span>
              </div>
            </div>
          </div>
          <div class="p-grid p-mt-3">
            <div class="p-col-12 p-md-4">
              <div class="p-field p-fluid">
                    <span class="p-float-label">
                        <Calendar id="delivery_date_materials" :showIcon="true" v-model="data.delivery_date_materials"
                                  dateFormat="dd/mm/yy"
                                  :yearNavigator="true" yearRange="2018:2050" :minDate="startDate" :maxDate="endDate"
                                  :class="{'p-invalid': submitted && (!data.delivery_date_materials && data.status===catalog.ok )}"
                                  class="p-inputtext-sm"/>
                        <label for="effective_date">Fecha de entrega materiales</label>
                    </span>
                <small class="p-invalid" v-if="submitted && !data.delivery_date_materials && data.status===catalog.ok">La
                  fecha de entrega es es requerida</small>
              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-field p-fluid">
                    <span class="p-float-label">
                        <Dropdown id="status_contract" v-model="data.status" :options="catalog.contractStatus"
                                  :show-clear="true"
                                  :class="{'p-invalid': submitted && (!data.status )}" class="p-inputtext-sm"/>
                        <label for="status_contract">Estado de matrícula</label>
                    </span>
                <small class="p-invalid" v-if="submitted && !data.status">El estado es requerido</small>
              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-field p-fluid">
                <div class="p-field  p-fluid">
                            <span class="p-float-label">
                                <AutoComplete id="jef" :dropdown="true" :multiple="false" field="name"
                                              v-model="data.verifier_user" :suggestions="employees"
                                              @complete="getEmployeesValidators($event, 'employees', 'name')"
                                              :class="{'p-invalid': submitted && (!data.verifier_user && data.status===catalog.ok)}"/>
                                <label for="jef">Persona que verificó</label>
                            </span>
                  <small class="p-invalid" v-if="submitted && !data.verifier_user && data.status===catalog.ok">La
                    persona es
                    requerida</small>
                </div>
              </div>
            </div>
          </div>
          <div class="p-grid p-mt-3">
            <div class="p-col-12 p-md-4">
              <div class="p-field p-fluid">
                            <span class="p-float-label">
                                <InputText id="referral_note" v-model="data.referral_note" class="p-inputtext-sm"
                                           :class="{'p-invalid': submitted && (!data.referral_note && data.status===catalog.ok)}"/>
                                <label for="referral_note">Nota de Remisión</label>
                            </span>
                <small class="p-invalid" v-if="submitted && !data.referral_note && data.status===catalog.ok">Nota de
                  Remisión
                  es requerida</small>
              </div>
            </div>
            <div class="p-col-12 p-md-4" v-if="data.re_entered">
              <div class="p-field p-fluid">
                    <span class="p-float-label">
                        <Calendar id="re_entered_at" :showIcon="true" v-model="data.re_entered_at"
                                  dateFormat="dd/mm/yy"
                                  :yearNavigator="true" yearRange="2018:2050"
                                  :class="{'p-invalid': submitted && (!data.re_entered_at && data.re_entered)}"
                                  class="p-inputtext-sm"/>
                        <label for="date_first_payment">Fecha reingreso</label>
                    </span>
                <small class="p-invalid" v-if="submitted && !data.re_entered_at && data.re_entered">Fecha
                  reingreso es requerida</small>
              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-field p-fluid">
                    <span class="p-float-label">
                        <Calendar id="date_first_payment" :showIcon="true" v-model="data.date_first_payment"
                                  dateFormat="dd/mm/yy"
                                  :yearNavigator="true" yearRange="2018:2050"
                                  :class="{'p-invalid': submitted && (!data.date_first_payment && data.status===catalog.ok)}"
                                  class="p-inputtext-sm"/>
                        <label for="date_first_payment">Fecha primer pago</label>
                    </span>
                <small class="p-invalid" v-if="submitted && !data.delivery_date_materials && data.status===catalog.ok">Fecha
                  primer pago es requerida</small>
              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-field-checkbox p-fluid">
                <Checkbox id="data_use_authorization" name="data_use_authorization" :binary="true"
                          v-model="data.data_use_authorization"/>
                <label for="data_use_authorization">Autorizo el uso de datos</label>
              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-field-checkbox p-fluid">
                <Checkbox id="is_re_entered" name="is_re_entered" :binary="true"
                          v-model="data.re_entered" @change="changeReEntered()"/>
                <label for="is_re_entered">Matr&iacute;cula es reingresada</label>
              </div>
            </div>
          </div>
          <div class="p-grid p-mt-3">
            <div class="p-col-12 p-md-12">
              <div class="p-field p-fluid">
                          <span class="p-float-label">
                               <AutoComplete id="campus" :dropdown="false" :multiple="true" field="name"
                                             v-model="data.campus" :suggestions="campus"
                                             @complete="getInfoSelectEmail($event, 'campus', 'name')"
                                             :class="{'p-invalid': submitted && (!data.campus)}"/>
                              <label for="employees_email">Configuraci&oacute;n de sedes</label>
                          </span>
              </div>
            </div>
          </div>
          <div class="p-grid p-jc-end p-mt-3">
            <div class="p-col-12 p-md-12">
              <div class="p-field p-fluid">
                    <span class="p-float-label">
                        <Textarea id="observation" v-model="data.observation" class="p-inputtext-sm" rows="4"/>
                        <label for="observation">Observación</label>
                    </span>
              </div>
            </div>
          </div>
          <div class="p-grid p-mt-3">
            <div class="p-col-12 p-md-4">
              <div class="p-field-checkbox p-fluid">
                <Checkbox id="send_notif_core" name="send_notif_core" :binary="true"
                          v-model="data.send_notif_core"/>
                <label for="send_notif_core">Enviar email a personal Natural English</label>
              </div>
            </div>
            <div class="p-col-12 p-md-8" v-if="data.send_notif_core===true">
              <div class="p-field p-fluid">
                          <span class="p-float-label">
                               <AutoComplete id="employees_email" :dropdown="false" :multiple="true" field="name"
                                             v-model="data.employees_email" :suggestions="employees"
                                             @complete="getInfoSelectEmail($event, 'employees', 'name')"
                                             :class="{'p-invalid': submitted && (!data.employees_email && data.send_notif_core)}"/>
                              <label for="employees_email">Personal a notificar</label>
                          </span>
              </div>
            </div>
          </div>
          <div class="p-grid p-jc-end"
               v-if="!infoNBS?.exist &&  infoNBS?.contract_status===catalog.ok && infoNBS?.enrolment_status===catalog.nbs ">
            <div class="p-col-12 p-md-12">
              <div class="p-message p-component p-message-warn" style="padding: 10px">
                <b>Contrato no se encuentra actualmente en sistema NBS3</b>
                <br/>
                <Button @click="sync2NbsFix" label="Enviar Contrato"
                        class="p-button-rounded p-button-secondary  p-m-2 p-button-sm"/>
              </div>
            </div>
          </div>
        </Fieldset>
      </template>
      <template #footer>
        <div class="p-grid p-jc-end">
          <Button label="Enviar NBS" icon="pi pi-share-alt" class="p-button-text" @click="sync2Nbs"
                  :disabled="data?.status === catalog.ok && data?.enrollment?.status===catalog.nbs"/>
          <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveContract"/>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import moment from 'moment'
import enrollService from '../../service/enrollment.service'
import contractService from '../../service/contract.service'
import dataService from '../../service/data.service'
import catalogService from "../../../core/service/catalog.service";
import {mapState} from "vuex";
// import {defineAsyncComponent} from "vue";

export default {
  name: "Contract",
  props: {
    enroll: Object
  },
  components: {
    // students: defineAsyncComponent(() => import('../students/StudentList')),
  },
  data: () => {
    return {
      data: {},
      verifPosition: 'verif',
      employees: [],
      countries: [],
      cities: [],
      positions: [],
      campus: [],
      catalog: dataService,
      submitted: false,
      loading: false,
      startDate: null,
      endDate: null,
      currentDate: null,
      infoNBS: null
    }
  },
  mounted() {
    catalogService.get(`dates`).then(response => {
      this.startDate = new Date(response.data.init_week);
      this.endDate = new Date(response.data.finish_week);
    });
    catalogService.get('positions').then(x => {
      this.positions = x.data;
    });
    contractService.get(this.enroll?.contract.id).then(response => {
      this.data = response.data;
      this.data.effective_date = new Date(moment(this.data.effective_date).format());
      this.data.re_entered = this.data.is_re_entered === 'S';
      if(this.data.is_re_entered === 'S') {
        this.data.re_entered_at = new Date(moment(this.data.re_entered_at).format());
      }
      this.data.data_use_authorization = this.data.data_use_authorization === 'y';
      this.data.week = this.enroll.week?.week;
      this.data.month = this.enroll.month?.month;
      const verifierUser = this.data.verifier_user?.employee;
      this.data.verifier_user = verifierUser ? verifierUser : this.data.verifier_user;
    });

    contractService.existsNBS(this.enroll?.contract.id).then(response => {
      this.infoNBS = response.data;
    }).catch((err) => {
      this.loading = false;
      const message = err.response.data;
      let error = this.$utils.formatError(message);
      this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
    });
  },
  methods: {
    changeReEntered(){
      this.data.is_re_entered = this.data.re_entered ? 'S':'N';
    },
    getEmployeesValidators(event, route, field) {
      catalogService.getCatalog(event, `${route}/validators`, field, {}).then(x => {
        this[route] = x;
      });
    },
    getInfoSelectEmail(event, route, field) {
      catalogService.getCatalog(event, `${route}/select-email`, field, {'q': event.query.toString().trim()}).then(x => {
        this[route] = x;
      });
    },
    getCatalog(event, route, field, country = null) {
      const originalRoute = route;
      if (route === 'cities') {
        if (!country) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Pais no seleccionado',
            detail: 'Por favor seleccione un pais',
            life: this.$utils.toastLifeTime()
          });
          return;
        }
        route = `countries/${country}/${route}`;
      }

      catalogService.getCatalog(event, route, field).then(x => {
        this[originalRoute] = x;
      }).catch(() => {
        this[originalRoute] = [];
      });
    },
    async sync2Nbs() {
      this.submitted = true;
      if (!this.data.status || !this.data.verifier_user || !this.data.country || !this.data.city) {
        return;
      }
      this.loading = true;
      await contractService.changeStatus(this.data.id, {status: this.data.status});
      enrollService.sync2Nbs(this.enroll.id).then(x => {
        this.loading = false;
        const message = x.data.message;
        this.$toast.add({severity: 'success', summary: 'Éxito', detail: message, life: this.$utils.toastLifeTime()});
        this.$nextTick(() => {
          this.$emit('closeSidebar', false)
        })
      }).catch((err) => {
        this.loading = false;
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })
    },
    async sync2NbsFix() {
      this.submitted = true;
      if (!this.data.status || !this.data.verifier_user || !this.data.country || !this.data.city) {
        return;
      }
      this.loading = true;
      await contractService.changeStatus(this.data.id, {status: this.data.status});
      enrollService.sync2NbsFix(this.enroll.id).then(x => {
        this.loading = false;
        const message = x.data.message;
        this.$toast.add({severity: 'success', summary: 'Éxito', detail: message, life: this.$utils.toastLifeTime()});
        this.$nextTick(() => {
          this.$emit('closeSidebar', false)
        })
      }).catch((err) => {
        this.loading = false;
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })
    },
    saveContract() {
      this.submitted = true;
      if ((!this.data.status || !this.data.country || !this.data.city)) {
        return;
      }
      if (this.data.status === this.catalog.ok && (!this.data.verifier_user ||
          !this.data.referral_note ||
          !this.data.delivery_date_materials ||
          !this.data.date_first_payment)) {
        return;
      }
      if (this.data.status === this.catalog.reEntry && !this.data.re_entered_at) {
        return;
      }
      this.loading = true;
      const params = {...this.data};
      params.data_use_authorization = this.data.data_use_authorization ? 'y' : 'n';
      params.effective_date = this.$filters.dateFormat(this.data.effective_date);
      params.delivery_date_materials = this.$filters.dateFormat(params.delivery_date_materials) + '' === "Invalid date" ? null : this.$filters.dateFormat(params.delivery_date_materials);
      params.date_first_payment = this.$filters.dateFormat(params.date_first_payment) + '' === "Invalid date" ? null : this.$filters.dateFormat(params.date_first_payment)
      params.verifier_user_id = params.verifier_user?.user_id;
      params.city_id = this.data.city?.id;
      params.country_id = this.data.country?.id;
      params.re_entered_at = null;
      if(params.is_re_entered === 'S') {
        params.re_entered_at = this.$filters.dateFormat(this.data.re_entered_at);
      }
      contractService.save(params, this.enroll.contract.id).then(() => {
        this.loading = false;
        this.$toast.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Guardado correctamente',
          life: this.$utils.toastLifeTime()
        });
        this.$nextTick(() => {
          this.$emit('closeSidebar', false)
        });
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => this.loading = false)
    }
  },
  computed: {
    ...mapState('enroll', ['country']),
    position() {
      return this.positions.find(x => x.description.toLowerCase().includes(this.verifPosition))
    },
    studentsNumber() {
      return this.enroll && this.enroll.is_holder_student === 'y' || this.enroll.is_holder_student === true ? this.enroll.students.length + 1 : this.enroll.students.length;
    }
  }
}
</script>

<style lang="scss">
.p-calendar {
  height: 30px !important;
}

.p-fieldset {
  margin-top: 5px;
}
.p-autocomplete-panel{
  left: 0!important;
}
.p-dropdown-items-wrapper{
  max-height: 400px!important;
}
</style>
